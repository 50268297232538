import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Helmet from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import CameraDetail from "components/cameras/detail/CameraDetailPage";

export default function R600() {
  const renderContent = data => {
    const camera = {
      model: "R600",
      type: "Multisensor",
      description:
        "Four independent sensors packed into one powerful camera for comprehensive coverage.",
      webHeader: data.webImage,
      mobileHeader: data.mobileImage,
      objectPosition: "20% top",
      dims: data.dims,
      pdf: "/files/specs/R600.pdf",
      specs: {
        column1: [
          {
            title: "Resolution",
            text: "Four 5MP sensor, 2592 x 1944 resolution",
          },
          {
            title: "Aperture",
            text: "F/2.0",
          },
          {
            title: "Pan / Tilt / Zoom",
            text: "Up to 2X digital PTZ",
          },
          {
            title: "Field of View",
            text: [
              "129 - 55° Diagonal FoV",
              "94 - 43° Horizontal FoV",
              "67 - 33° Vertical FoV",
            ],
          },
          {
            title: "Focal Length",
            text: "3.7mm - 7.7mm",
          },
          {
            title: "Dimensions",
            text: "275mm (10.8 in) x 118 mm (4.6 in)",
          },
          {
            title: "Weight",
            text: "3.63kg (8.00lbs)",
          },
          {
            title: "Weather Resistance",
            text: "IP66",
          },
          {
            title: "Impact Resistance",
            text: "IK10",
          },
          {
            title: "Night Vision",
            text: "Infrared illuminators (100 ft with full FoV coverage)",
          },
        ],
        column2: [
          {
            title: "Ethernet",
            text: "10 / 100Mbps, MDI / MDI-X Auto Sensing",
          },
          {
            title: "Power-Over-Ethernet",
            text: "PoE ++ (802.3 bt) for one-cable installation",
          },
          {
            title: "Power Consumption",
            text: "Max 51 watts",
          },
          {
            title: "Operating Temperature",
            text: ["-30˚ to 55˚ C", "-22˚ to 131˚ F"],
          },
          {
            title: "Operating Humidity",
            text: "90% RH",
          },
          {
            title: "In the Box",
            text: "Torx key, mounting screws, anchors, waterproofing kit",
          },
          {
            title: "Baseline Steady State",
            text: "10 - 30 Kbps per camera",
          },
          {
            title: "HD WAN Live Streaming",
            text: "530 - 720 Kbps per camera",
          },
          {
            title: "Compliance",
            text: "CE, FCC, IC, UK, AUS, NZ, NDAA, TAA Compliant",
          },
          {
            title: "Rhombus Sensor Network Compatible",
          },
          {
            title: "10-Year Warranty Included",
          },
        ],
      },
      accessoryCompatible: true,
      sensorCompatible: true,
      exploreModels: {
        title: "Explore Other Cameras",
        subtitle:
          "Find the solution that's best suited for your needs. Compare all camera models or view other models below.",
        cameraCards: [
          {
            image: data.r120Camera,
            mobileImage: data.r120CameraMobile,
            alt: "dome camera model",
            width: "189px",
            model: "Dome",
            useCase: "Ideal for most environments",
            link: "/cameras/dome-cameras/",
          },
          {
            image: data.r500Camera,
            mobileImage: data.r500CameraMobile,
            alt: "bullet camera model",
            width: "195px",
            model: "Bullet",
            useCase: "Ideal for outdoor environments",
            link: "/cameras/bullet-cameras/",
          },
          {
            image: data.r360Camera,
            mobileImage: data.r360CameraMobile,
            alt: "fisheye camera model",
            width: "195px",
            model: "Fisheye",
            useCase: "Ideal for indoor environments",
            link: "/cameras/bullet-cameras/",
          },
        ],
      },
    };
    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>R600 Multisensor Security Camera</title>
          <meta
            name="description"
            content="20 MP resolution in one powerful camera for comprehensive coverage"
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/MetaTag-R600-min.png"
          />
          <meta
            property="og:image"
            content="https://rhombus.com/img/MetaTag-R600-min.png"
          />
        </Helmet>
        <CameraDetail camera={camera} />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r400-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/multisensor-cameras/assets/r600-dims.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      r120Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r120-200-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r120CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r120-200-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r500Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r500-510-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r500CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r500-510-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r360Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r360.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r360CameraMobile: file(
        relativePath: { eq: "components/cameras/img/explore-r360-mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
